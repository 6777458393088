<template>
  <div class="application">
    <div class="banner_wrap">
      <img :src="banner" alt="" />
      <div class="banner-title">连接市场供求，赋能AI生态</div>
    </div>
    <div class='search-wrap'>
      <el-input placeholder="输入应用名称查找" @keyup.enter.native="queryTable" v-model='queryTerms.name'></el-input>
      <el-button type='primary' icon='el-icon-search' @click='queryTable'>查 询</el-button>
    </div>
    <div class="main_wrap">
      <div class="filter_content">
        <div class="filter_item">
          <div class="title">应用类别：</div>
          <div class="select_wrap">
            <div class="select-item" :class="{ 'active' : !queryTerms.appTypes.length }" @click="selectAll">全部</div>
            <div class='select-item' v-for='(item,index) in appTypeList' :key='index'
              :class="{ 'active' : queryTerms.appTypes.includes(item.value) }" @click='selectTypeItem(item)'>
              {{ item.name }}</div>
          </div>
        </div>
        <div class="filter_item" v-if="sceneList.length">
          <div class="title">应用场景：</div>
          <div class="select_wrap">
            <div class='select-item' v-for='(item,index) in sceneList' :key='index'
              :class="{ 'active' : queryTerms.sceneTags.includes(item.value) }" @click='selectSceneItem(item)'>
              {{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="main_content" v-loading='tableLoading'>
        <template v-if="!tableLoading">
          <div v-if='appList && appList.length > 0' class="app_list">
            <div class='app-item' v-for='(item,index) in appList' :key='index' @click='checkDetail(item)'>
              <div class="right_arrow">
                <i class="el-icon-arrow-right"></i>
              </div>
              <el-button class="experience_btn">立即体验</el-button>
              <div class="category">
                <div class="item" v-for='(e,i) in item.sceneTags' :key='e.index'
                  :style="styleStatusColor2(Number(item.sceneTag.split(',')[i]))">{{ e }}</div>
              </div>
              <div class='app-img'>
                <img @error="imageLoadError" :src="item.picurl" />
                <div class="app_type" :style="styleStatusColor(item.appType)">{{ item.appTypeName }}</div>

              </div>
              <div class="item_info">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="introduce">{{ item.introduce }}</div>
                <div class="companyName">
                  {{item.companyName}}
                </div>
              </div>
            </div>
          </div>
          <div class='empty' v-else>
            <img src="@/assets/images/empty.svg" alt="">
            <span>暂无数据</span>
          </div>
        </template>
        <div class="paginationBar" v-if='appList && appList.length > 0'>
          <el-pagination @current-change="changePage" :current-page.sync="page.pageNum" :page-size="page.pageSize"
            @size-change="sizeChange" layout="total, prev, pager, next,jumper" :total="page.total">
          </el-pagination>
        </div>
      </div>

    </div>
    <common-footer></common-footer>
  </div>
</template>
<script>
/* eslint-disable */
import commonFooter from '@/components/commonFooter'
import api from '@/api/api'
import defaultImage from '@/assets/images/defaultImg.svg';
export default {
  components: {
    commonFooter,
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const type = this.$route.query.type;
    const searchKey = window.localStorage.getItem('searchKey');
    this.queryTerms.sceneTags = [];
    this.queryTerms.appTypes = type ? [type] : [];
    if (searchKey) {
      this.queryTerms.name = searchKey;
    }
    this.queryTable()
    this.queryDictAppType()
  },
  data () {
    return {
      baseUrl: '/oss',
      banner: require('@/assets/images/home/banner.png'),
      imglist: [
        require('@/assets/images/banner.png')
      ],
      searchKey: '',
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      appTypeList: [],
      sceneList: [],
      appList: [],
      queryTerms: {
        name: '',
        appTypes: [],
        sceneTags: [],
      },
      tableLoading: false,
      defaultImage,
    }
  },
  computed: {
    styleStatusColor () {
      return function (v) {
        const colorMap = {
          0: '#5b9bd5',
          1: '#ed7d31',
          2: '#70ad47',
          3: '#ffc000',
          4: '#4472c4',
          5: '#b235e6',
          6: '#91d024',
          7: '#02ae75',
        };
        const color = colorMap[v];
        return color ? `border: 1px solid ${color}; color: ${color};` : '';
      }
    },
    styleStatusColor2 () {
      return function (v) {
        const colorMap = {
          0: '#63b2ee',
          1: '#76da91',
          2: '#f8cb7f',
          3: '#f89588',
          4: '#7cd6cf',
          5: '#9192ab',
          6: '#7898e1',
          7: '#efa666',
          8: '#eddd86',
          9: '#9987ce',
          10: '#9787ce',
          11: '#9687ce',
          12: '#b587ce',
          13: '#7487ce',
          14: '#0387ce',
          15: '#5287ce',
          16: '#e187ce',
        };
        const hex = colorMap[v];
        if (!hex) return '';
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const rgba = `rgba(${r}, ${g}, ${b}, 0.1)`;
        return `border: 1px solid ${hex}; color: ${hex}; background-color: ${rgba};`;
      }
    },
  },
  methods: {
    selectAll () {
      this.queryTerms.appTypes = []
      this.queryTerms.sceneTags = []
      this.sceneList = []
      this.queryTable()
    },
    imageLoadError (event) {
      event.target.src = this.defaultImage;
    },
    sizeChange (size) {
      this.page.pageSize = size
      this.queryTable()
    },
    changePage (page) {
      this.page.pageNum = page
      this.queryTable()
    },
    queryDictAppType () {
      api.queryDictDetail('app_type').then(res => {
        if (res.code == 200) {
          this.appTypeList = [...res.data];
          if (this.queryTerms.appTypes.length) {
            const labelCode = this.appTypeList.filter(item => item.value == this.queryTerms.appTypes[0])[0].code
            this.queryDictSence(labelCode)
          }
        }
      })
    },
    queryDictSence (scence) {
      api.queryDictDetail(scence).then(res => {
        if (res.code == 200) {
          this.sceneList = [...res.data];
        }
      })
    },
    queryTable () {
      this.tableLoading = true;
      let params = { ...this.queryTerms }
      api.queryGateWayApp({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        ...params
      }).then(res => {
        if (res.code == 200) {
          this.appList = res.data.list.map(item => {
            return {
              ...item,
              picurl: window.location.origin + '/ecol/app/image?path=/' + item.picture
            }
          })
          if (window.localStorage.searchKey) window.localStorage.removeItem('searchKey')
        }
        this.page.total = res.data.totalRows
        this.tableLoading = false
      })
        .catch(err => {
          this.tableLoading = false
        })
    },
    publishApp () {
      if (window.localStorage.ecolUserInfo) {
        let id = JSON.parse(window.localStorage.getItem('ecolUserInfo')).companyId
        this.$router.push('/appPublish')
      } else {
        this.$router.push('/login')
      }
    },
    selectTypeItem (e) {
      this.queryDictSence(e.code);
      this.queryTerms.appTypes = [e.value];
      this.queryTerms.sceneTags = []
      this.queryTable()
    },
    selectSceneItem (e) {
      // const set = new Set(this.queryTerms.sceneTags);
      // if (set.has(e.value)) {
      //   set.delete(e.value);
      // } else {
      //   set.add(e.value);
      // }
      // this.queryTerms.sceneTags = Array.from(set);

      this.queryTerms.sceneTags = [e.value]
      this.queryTable()
    },
    checkDetail (e) {
      if ([3, 4, 5].includes(e.appType)) {
        this.$router.push(`/appExperience/${e.id}/${e.companyName}/${e.appType}`)
      } else {
        this.$router.push({
          path: '/appDetail',
          query: {
            id: e.id,
            companyName: e.companyName
          }
        })
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.application {
  background: #f5f5f5;
  position: relative;
  min-height: calc(1080px - 69px);
  .banner_wrap {
    position: relative;
    img {
      width: 1920px;

      height: 220px;
    }
    .banner-title {
      position: absolute;
      font-size: 69.33px;
      font-family: PangMenZhengDao;
      font-weight: normal;
      text-align: justify;
      color: #ffffff;
      line-height: 78.67px;
      letter-spacing: 4.67px;
      text-shadow: 2.67px 5.33px 0px 0px rgba(62, 111, 250, 0.5);
      z-index: 11;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%);
    }
  }
  ::v-deep.search-wrap {
    position: absolute;
    width: 1066.67px;
    height: 56px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 37.33px;
    box-shadow: 0px 4px 12px 0px rgba(12, 64, 121, 0.12),
      0px 4px 8px -8px rgba(0, 0, 0, 0.04);
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    top: 190px;

    .el-input__inner {
      box-sizing: border-box;
      width: 100% !important;
      height: 100% !important;
      border-radius: 37.33px;
      padding-left: 60px;
      border: none !important;
      font-size: 16px;
    }
    .el-button {
      position: absolute;
      width: 146.67px;
      height: 56px;
      background: #2774f7;
      border-radius: 37.33px;
      box-shadow: 0px 4px 12px 0px rgba(12, 64, 121, 0.12),
        0px 4px 8px -8px rgba(0, 0, 0, 0.04);
      right: 0px;
      border: 1px solid #2774f7;
      font-size: 16px;
    }
  }
  .empty {
    text-align: center;
    padding-top: 200px;
    font-size: 16px;
    color: #4e494996;
    display: flex;
    font-weight: bold;
    flex-direction: column;
    align-items: center;
    
    img {
      width: 200px;
      height: 200px;
    }
  }
  .active {
    background: #3d64ff !important;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    color: #ffffff !important;
  }
  ::v-deep.main_wrap {
    /* width: 1440px; */
    width: 1540px;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 134px;
    .el-loading-spinner {
      margin-top: 100px;
    }
    .filter_content {
      .filter_item {
        display: flex;

        align-items: baseline;

        .title {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          text-align: left;
          color: #a8a8a8;
          width: 100px;
          display: flex;
        }
        .select_wrap {
          display: flex;
          flex: 1;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .select-item {
          cursor: pointer;
          background: #fff;
          border: 1px solid #dcdfe6;
          width: 140px;
          color: #67696f;
          padding: 12px;
          font-size: 14px;
          border-radius: 6px;
          margin: 0 16px 16px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            color: #3d64ff;
            border-color: #c5d1ff;
            background-color: #ecf0ff;
          }
        }
      }
    }
    .main_content {
      position: relative;
    }
    .experience_btn {
      position: absolute;
      right: 46px;
      bottom: 14px;
      width: 105px;
      height: 36px;
      background: #2774f7;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #ffffff;
    }
    .app_list {
      .app-item {
        position: relative;
        display: flex;
        margin: 0 auto;
        margin-bottom: 16px;
        box-sizing: border-box;
        width: 1340px;
        /* width: 1540px; */
        height: 156px;
        background: #ffffff;
        border-radius: 8px;

        padding: 16px;
        box-shadow: 1px 1.73px 12px 0px rgba(0, 0, 0, 0.1);
        .right_arrow {
          position: absolute;
          right: 18px;
          bottom: 20px;
          font-size: 18px;
          color: #a8a8a8;
          cursor: pointer;
        }
        .category {
          position: absolute;
          right: 0px;
          top: 18px;
          display: flex;
          .item {
            margin-right: 18px;
            padding: 7px 17px;
            border-radius: 4px;
            font-size: 12px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 700;
          }
        }
        .app-img {
          cursor: pointer;
          width: 148px;
          height: 124px;
          position: relative;
          margin-right: 16px;
          position: relative;
          border: 1px solid #f1eaea;
          img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .app_type {
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 12px !important;
            background: #ffffff;
            border: 1px solid #2774f7;
            border-radius: 4px;
            padding: 4px;
          }
        }

        .item_info {
          box-sizing: border-box;
          flex: 1;
          padding: 9px 0;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #2774f7;
            margin-bottom: 23px;
          }
          .introduce {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #a8abb2;
            line-height: 22px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 800px;
          }
          .companyName {
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }

    .paginationBar {
      text-align: right;
      width: 1340px;
      margin: 0 auto;
      .el-pagination {
        padding: 0px !important;
        .btn-next,
        .btn-prev {
          margin: 0;
          background: transparent;
        }
        .el-pager {
          li {
            background: #f5f5f5;
          }
          li.active {
            background: #ffffff;
            border: 1px solid #2774f7;
            border-radius: 4px;
          }
        }
        span {
          font-size: 14px;
          font-weight: bold;
          text-align: right;
          color: #333333;
        }
        .el-pagination__editor {
          margin: 0 8px;
          .el-input__inner {
            background: transparent;
          }
        }
      }
    }
  }

  ::v-deep footer {
    position: absolute;
  }
}
</style>

